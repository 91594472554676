<script>
  import appConfig from "@/app.config";
  import PageHeader from "@/components/page-header";
  import store from "@/state/store";
  var state = store.state;
  export default {
    page: {
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    components: {
      PageHeader,
    },
    data() {
      return {
        archive_files: [],

        ArchiveFileTypes: [],
        page: 1,
        limit: 20,
        tot_pages: 0,
        searchArchiveFileTypes: null,
        ArchiveFileTypesSearchMode: false,
        searchModel: "",
        state: state,
      };
    },
    methods: {
      addArchiveFileTypes() {
        console.log("**********####", this.state);
        var data = {
          title: "popups.addArchiveFileTypes",
          inputs: [{ model: "name", type: "text", label: "name" }],
          buttons: [
            {
              text: "popups.add",
              closer: true,
              color: "primary",
              handler: (obj) => {
                this.http.post("archive-shelves", obj).then(() => {
                  this.get(this.page);
                });
              },
            },
            {
              text: "popups.cancel",
              closer: true,
              color: "danger",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.modal(data);
      },
      editArchiveFileTypes(app) {
        var data = {
          title: "popups.archive_file_types",
          inputs: [
            { model: "name", type: "text", label: "name", value: app.name },
          ],
          buttons: [
            {
              text: "popups.edit",
              closer: true,
              color: "primary",
              handler: (obj) => {
                this.http.put("archive-shelves", app.id, obj).then(() => {
                  this.get(this.page);
                });
              },
            },
            {
              text: "popups.cancel",
              closer: true,
              color: "danger",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.modal(data);
      },
      updateArchiveFileTypes(app) {
        var data = {
          title: "popups.editfile",
          inputs: [
            { model: "name", type: "text", label: "name" },
            {
              model: "archive_files",
              type: "select",
              options: (() => {
                return this.archive_files.map((data) => {
                  return { value: data.id, label: data.id };
                });
              })(),
              label: "popups.archive_files",
            },
          ],
          buttons: [
            {
              text: "popups.edit",
              closer: true,
              color: "primary",
              handler: (obj) => {
                obj.emp_id = app.id;
                this.http.do("archive-shelves", obj).then(() => {
                  this.get(this.page);
                });
              },
            },
            {
              text: "popups.cancel",
              closer: true,
              color: "danger",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.modal(data);
      },
      search() {
        this.ArchiveFileTypesSearchMode = true;
        this.tot_pages = 0;
        this.http
          .post("archive-shelves/search", {
            search: this.searchModel,
            limit: this.limit,
            page: this.page,
            col: "name",
          })
          .then((res) => {
            this.ArchiveFileTypes = res.data;
            console.log("######## files", this.ArchiveFileTypes);
          });
      },
      cancelappsearchMode() {
        this.searchModel = "";
        this.ArchiveFileTypesSearchMode = false;
        this.get(this.page);
      },

      getArchiveFileTypes() {
        this.http.get("archive-shelves").then((res) => {
          this.ArchiveFileTypes = res.data;
        });
      },
      deleteArchiveFileTypes(app) {
        var data = {
          title: "popups.deletefile",
          msg: "popups.delmessage",
        };
        this.popup.confirm(data).then((resp) => {
          if (resp) {
            console.log("###");
            this.http.delete("archive-shelves", app.id).then(() => {
              console.log("############################3");
              this.get(this.page);
            });
          }
        });
      },
      navigate(app) {
        this.$router.push(`/archiving_shelves/${app.id}`);
      },
      get(page) {
        console.log(page);
        this.http
          .post("archive-shelves/pagination", {
            limit: this.limit,
            page: page,
          })
          .then((res) => {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            this.ArchiveFileTypes = res.data;
            res.data.forEach((item) => {
              this.archive_files.push(item.archive_files);
            });
          });
      },
    },
    created() {
      this.get(1);
    },
  };
</script>

<template>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.archiving.subItems.archiving_shelves')"
    />
    <!-- <div class="d-flex align-items-center mb-4">
      <router-link :to="`/archiving_categories_management`">
        <h4 class="m-0">{{ $t("archiving_categories.text") }}</h4>
      </router-link>
      <div class="mx-2">\</div>
      <div>{{ $t("archiving_categories.shelves") }}</div>
    </div> -->
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="ArchiveFileTypesSearchMode"
          @click="cancelappsearchMode()"
        ></button>
        <button
          type="button"
          @click="addArchiveFileTypes()"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">
            {{ $t("archiving_categories.archiving_categories_table.id") }}
          </th>
          <th scope="col">
            {{ $t("archiving_categories.archiving_categories_table.name") }}
          </th>
          <th scope="col">
            {{ $t("archiving_categories.archiving_categories_table.created") }}
          </th>
          <th scope="col">
            {{ $t("archiving_categories.archiving_categories_table.updated") }}
          </th>
          <th scope="col">
            {{ $t("popups.details") }}
          </th>
          <th scope="col">
            {{
              $t("archiving_categories.archiving_categories_table.operations")
            }}
          </th>
          "
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, i) in ArchiveFileTypes" :key="app" class="text-center">
          <td>{{ i + 1 }}</td>
          <td>{{ app.name }}</td>
          <td>{{ app.created.split("T")[0] }}</td>
          <td>{{ app.updated.split("T")[0] }}</td>
          <td>
            <button @click="navigate(app)" class="btn btn-primary btn-sm me-2">
              <i class="bx bx-detail"></i>
            </button>
          </td>
          <td class="d-flex justify-content-center">
            <a
              class="btn btn-primary mx-1"
              @click="editArchiveFileTypes(app)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.edit") }}</a
            >
            <a
              class="btn btn-danger"
              href="javascript: void(0);"
              role="button"
              @click="deleteArchiveFileTypes(app)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  Pagination     -->
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->

</template>

<style scoped>
  .btn-close {
    position: relative;
    right: 317px;
    top: 6px;
    font-size: 15px;
  }
</style>
